import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl, getHeader } from "../utils/api";

export const getAllCenters =
  ({ page, limit }) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.post(
        `${baseUrl}/admin/verified-center`,
        {
          page: page,
          limit: limit,
        },
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        // dispatch(setAllCenterData(response?.data))
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("getAllCenterData  error:", errorMessage);
        toast.error(errorMessage);
      }
    }
  };

  export const getCenterById =
  ({ id }) =>
  async () => {
    console.log("Enter",id);
    try {
      const response = await axios.get(
        `${baseUrl}/admin/center/${id}`,
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center  error:", errorMessage);
        toast.error(errorMessage);
      }
    }
  };


  export const getComments =
  ({ id }) =>
  async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/comments/${id}`,
      );
      if (response?.status === 200 || response?.status === 201) {
        console.log("Enter.response,",response)
        return response?.data?.result?.comments;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center  error:", errorMessage);
        // toast.error(errorMessage);
      }
    }
  };

// export const searchCenters =

//   ({ centerName, country, state,page,limit }) =>
   
//   async () => {
//     console.log("entercnter",centerName)
//     try {
//       const header = await getHeader();
//       const response = await axios.get(
//         `${baseUrl}/admin/search-center?page=${page}&limit=${limit}&query=${centerName.trim()}`,
//         {
//           headers: header,
//         }
//       );
//       if (response?.status === 200 || response?.status === 201) {
//         // dispatch(setAllCenterData(response?.data))
//         return response?.data;
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       if (error?.response?.data) {
//         const errorMessage = error.response.data.message;
//         console.log("search  error:", errorMessage);
//       }
//     }
//   };

export const searchCenters = ({ centerName, country, state, page, limit }) => 
  async () => {
    console.log("entercnter", centerName);
    try {
      const header = await getHeader();

      // Handle centerName as a string or an array
      const query =
        Array.isArray(centerName) 
          ? centerName.join(', ').trim() // Convert array to a comma-separated string
          : (centerName || '').trim();   // Ensure centerName is a string and trim

      const response = await axios.get(
        `${baseUrl}/admin/search-center?page=${page}&limit=${limit}&query=${query}`,
        {
          headers: header,
        }
      );

      if (response?.status === 200 || response?.status === 201) {
        // dispatch(setAllCenterData(response?.data))
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("search error:", errorMessage);
      }
    }
  };


  export const searchSuggestion =
  ({ search}) =>
  async () => {
    try {
      const header = await getHeader();
      const response = await axios.get(
        `${baseUrl}/admin/suggestion-center?search=${search}`,
        {
          headers: header,
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        // dispatch(setAllCenterData(response?.data))
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get suggestion  error:", errorMessage);
      }
    }
  };

export const getStateForCountry=
({ country }) =>
  async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/state-list?country=${country}`,
      );
      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data) {
        const errorMessage = error.response.data.message;
        console.log("get center  error:", errorMessage);
        toast.error(errorMessage);
      }
    }
  };
